<template>
    <div class="w-full h-full banner">
        <div class="flex flex-col items-center justify-between h-full py-10">
            <div></div>
            <div class="flex flex-col justify-center items-center align-middle">
                <NotifyaLogo classes="text-6xl text-white" />
                <span class="text-base text-center text-white mt-6 w-2/3">
                    Už jen krůček vás dělí od efektivnějšího řízení vašeho
                    podnikání!
                    <br />Zaregistrujte se a začněte využívat všechny výhody
                    naší platformy hned teď. Jsme tu pro vás, abychom vám
                    usnadnili každodenní práci.
                </span>
            </div>
            <span class="text-base text-center text-slate-200 mt-6 w-2/3">
                Potřebujete pomoc nebo máte dotaz?
                <a
                    href="https://notifya.cz/contact"
                    class="text-white underline"
                    >Kontaktujte nás</a
                >
            </span>
        </div>
    </div>
</template>
<style scoped>
.left {
    background: -webkit-linear-gradient(to top right, #fc466b, #3f5efb);
    background: linear-gradient(to top right, #fc466b, #3f5efb);
}
</style>